import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Navbar from "../custom/Navbar"
import ContactSection from "../custom/ContactSection"
import WhySection from "../custom/WhySection"
import ResumeSection from "../custom/ResumeSection"
import WorkSampleSection from "../custom/WorkSampleSection"
import ProjectList from "../custom/ProjectList"

const SecondPage = () => {
  const buttonStyle = {
    backgroundColor: "#0b6cff",
    color: "white",
    padding: "0.5rem 1rem",
    borderRadius: "0.5rem",
  }

  const companyName = "AccelByte"
  const jobTitle = "Frontend Engineer"

  return (
    <Layout customClass={"bg-yellow-50"}>
      <Seo title={`Meet your new ${jobTitle}`} />
      <Navbar companyName={companyName} buttonStyle={buttonStyle} />
      <WhySection companyName={companyName} />
      <ResumeSection />
      <WorkSampleSection />
      <ProjectList />
      <ContactSection buttonStyle={buttonStyle} />
    </Layout>
  )
}

export default SecondPage
